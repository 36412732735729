import {
  CisRate,
  Color,
  ImageGallery,
  KeyValue,
  OfflineStock,
  OfflineStockResponse,
  PreSelection,
  SkuVariant,
  StockAvailability
} from '../types/product.types';
import { FLOW, Plan, PlanGroup } from '../types/plan.types';
import { api } from '@detox/actions';
import isFeatureFlagEnabled from './feature-flags';
import {
  FEATURE_FLAG_MTPOS_FS_API_MIGRATION,
  ONLINE_STOCK_FROM_ESHOP
} from '../types/featureFlag.types';
import { PRODUCT } from '../constants/product';
import {
  DeliveryOptions,
  IFulfilment,
  IVariant
} from '@common-modules/product-catalogue';
import { getUrlParams, setUrlParams } from '@lux/helpers';
import { constructProductUrl, updateUtmUrl } from './catalogue-helpers';
import unlimitedTalktimeSms from '../helpers/unlimited-talktime-sms';
import { FulfilmentMode } from '../types/fulfilment.types';
import { SelectedPlan } from '../types/shoppingCart.types';
import { includes } from './common';
import CONSTANTS from '../constants/common';
import { trans as t } from '../helpers/localisation';
import slugify from 'slugify';
import { planTagsConfig } from '../config/plan-group-config';
import { PlanTagParams } from '../components/PlanCatalogCard/PlanCatalogCard';

const isFSMtposMigrationEnabled = isFeatureFlagEnabled(
  FEATURE_FLAG_MTPOS_FS_API_MIGRATION
);
const fetchOnlineStock = isFSMtposMigrationEnabled
  ? api.onlineApi.eShopStockAvailability
  : api.mcss.stockAvailability;
const fetchOfflineStock = api.aem.offlineStockAvailability;

const getColorHexCode = (colors: Color[] = [], colorName: string) => {
  return colors.find(color => color.name === colorName)?.hexCode;
};
const getImagesByColor = (
  colorCode: string,
  imageGallery: ImageGallery[] = [],
  title: string
) => {
  const filteredImages = imageGallery
    .find(gallery => gallery.name === colorCode)
    ?.images?.map(image => {
      return {
        imageFile: image.image,
        alt: `${title} ${colorCode}`
      };
    });
  return filteredImages || [];
};

const getDeviceVariants = async ({
  skuVariants = [],
  plans = [],
  colors = [],
  seoImageTag,
  imageGallery,
  groupId,
  tags = [],
  brand,
  sellingFastThreshold,
  productTitle,
  needStockCheck,
  checkStockFromStore
}: {
  skuVariants: SkuVariant[];
  colors: Color[];
  imageGallery: ImageGallery[];
  seoImageTag: string;
  plans?: Plan[];
  groupId?: string;
  tags?: string[];
  brand?: string;
  sellingFastThreshold?: number;
  productTitle?: string;
  needStockCheck?: boolean;
  checkStockFromStore?: boolean;
}): Promise<{ status: boolean; variants: IVariant[] }> => {
  const skus = skuVariants.map(s => s.sku);
  let stockAvailability;
  if (needStockCheck) {
    stockAvailability = await checkStock(skus, groupId, checkStockFromStore);
  }

  const formattedVariants = skuVariants.map(skuVariant => {
    const planGroup = plans.find(plan => plan.mecId === skuVariant.planId)
      ?.groupName;
    const stock = stockAvailability?.stock?.find(
      stock => stock.itemCode === skuVariant.sku
    );
    const stockCount = stock?.availableQty;
    return {
      ...skuVariant,
      deviceId: skuVariant.productId,
      colorCode: skuVariant.colour,
      colorHexCode: getColorHexCode(colors, skuVariant.colour),
      deviceCode: skuVariant.sku,
      deviceDescription: '',
      modelCode: skuVariant.productId,
      modelDescription: '',
      memorySize: skuVariant.size,
      colorDescription: skuVariant.colour,
      msaHeaderId: '',
      planId: skuVariant.planId,
      price: skuVariant.price,
      discountPrice: 0,
      stockCount,
      groupId,
      images: getImagesByColor(skuVariant.colour, imageGallery, seoImageTag),
      planGroup,
      hasOfflineStock: stock?.hasOfflineStock,
      hasOnlineStock: stock?.hasOnlineStock,
      popStationAvailable: tags.includes(PRODUCT.TAGS.POP_STATION),
      brand,
      seoImageTag: seoImageTag,
      deviceTitle: productTitle,
      installmentPrice:
        plans?.length || skuVariant.monthlyTerm
          ? skuVariant.installmentPrice
          : 0,
      storageHighlightText:
        sellingFastThreshold &&
        stockCount &&
        stockCount <= sellingFastThreshold &&
        t('TEXT_SELLING_FAST')
    };
  });
  return { status: stockAvailability?.status, variants: formattedVariants };
};

const getPlanGroups = (
  allPlanGroup: PlanGroup[],
  plans: Plan[],
  flow: FLOW
): PlanGroup[] => {
  const uniqueAvailableGroup = [...new Set(plans.map(plan => plan.groupName))];
  return allPlanGroup.filter(
    group =>
      uniqueAvailableGroup.includes(group.groupName) &&
      group?.segment?.includes(flow)
  );
};

export const getPlanTag = ({
  planId,
  planTags,
  priorityPlanIds = []
}: PlanTagParams) => {
  const is5g = planTags?.includes(planTagsConfig['5g-speed'].tagName);
  const isPriority = priorityPlanIds.includes(planId);
  switch (true) {
    case is5g && isPriority: {
      return '5G+ Priority';
    }
    case is5g: {
      return '5G+';
    }
    default: {
      return '4G';
    }
  }
};

const getPlans = (
  plans: Plan[],
  cisRates?: CisRate[],
  priorityPlanIds: string[] = [],
  defaultPlanIds: string[] = []
) => {
  return plans.map(plan => {
    const { talktime, sms } = plan;
    const smsAndTalkTimeLiner = unlimitedTalktimeSms({ talktime, sms });

    const returnPlan = {
      ...plan,
      tag: getPlanTag({
        planId: plan.planId,
        planTags: plan.tags,
        priorityPlanIds
      }),
      defaultSelected: defaultPlanIds.length
        ? defaultPlanIds.includes(plan.planId)
        : plan.selected,
      smsAndTalkTimeLiner
    };
    if (!cisRates) {
      return returnPlan;
    }
    const matchedCisRate = cisRates.find(
      cisRate => cisRate.planID === plan.basePriceSchemaId
    );
    const monthlyCharges = Number(plan.monthlyCharges);
    const discountedPrice = Number(matchedCisRate?.discountedPrice);
    if (monthlyCharges === discountedPrice) {
      return returnPlan;
    }
    return {
      ...returnPlan,
      discountedPrice
    };
  });
};

export const getProductSpecsWithoutStorage = (
  specString: string,
  colours?: Color[],
  isRRP?: boolean
) => {
  let color = null,
    planInfo = null;
  const urlColor = colours?.find(colour =>
    specString.includes(slugify(colour?.name.toLowerCase()))
  )?.name;
  if (urlColor) {
    const selectedColor = slugify(urlColor.toLowerCase());
    const splitInfoByColor = specString.split(selectedColor + '-');
    color = selectedColor;
    const planRegex = splitInfoByColor[1]?.split('-') ?? [];
    const hasWrongStorage = /\d/.test(planRegex[0]);
    if (hasWrongStorage && !isRRP) {
      planInfo = planRegex.slice(1);
    } else {
      planInfo = planRegex;
    }
  }

  return {
    colorValue: color,
    planInfoValue: planInfo
  };
};

const getProductSpecsFromUrl = (): PreSelection => {
  if (typeof window === 'undefined') {
    return {};
  }
  const urlParams = getUrlParams();
  return {
    color: urlParams[CONSTANTS.URL_PARAMS.COLOR],
    storage: urlParams[CONSTANTS.URL_PARAMS.SIZE],
    plan: urlParams[CONSTANTS.URL_PARAMS.PLAN],
    monthlyTerm: urlParams[CONSTANTS.URL_PARAMS.MONTHLY_TERM],
    paymentTerm: urlParams[CONSTANTS.URL_PARAMS.PAYMENT_TERM]
  };
};

const setUrlAfterChangeVariant = (
  variant: KeyValue,
  slug: string,
  locationStr: string,
  isRRP = false
) => {
  const utmParams = updateUtmUrl(locationStr);
  const { colorCode, planName, memorySize, monthlyTerm, paymentTerm } = variant;
  window.history.replaceState(
    null,
    null,
    setUrlParams(
      `/${
        isRRP ? CONSTANTS.URL_PATHS.RRP_PHONE : CONSTANTS.URL_PATHS.PHONE
      }/${slug}`,
      {
        ...utmParams,
        ...constructUrlParams({
          [CONSTANTS.URL_PARAMS.COLOR]: colorCode,
          [CONSTANTS.URL_PARAMS.PLAN]: planName,
          [CONSTANTS.URL_PARAMS.SIZE]: memorySize,
          [CONSTANTS.URL_PARAMS.MONTHLY_TERM]: isRRP ? undefined : monthlyTerm,
          [CONSTANTS.URL_PARAMS.PAYMENT_TERM]: paymentTerm
        })
      }
    )
  );
};

const filterOfflineStocks = (
  offlineStocks: OfflineStock[] = [],
  skus: string[] = []
): StockAvailability[] => {
  return skus.map(skuId => {
    const matchedSku = offlineStocks.find(
      offlineStock => offlineStock.skuId === skuId
    );
    const skuHasStock = matchedSku?.data?.find(
      stockAvailability =>
        PRODUCT.STOCK_AVAILABLE_STATUS.includes(
          stockAvailability.stock_status
        ) &&
        Number(stockAvailability.available_quantity) > 0 &&
        stockAvailability.store_id !== PRODUCT.STORE_ID.WARE_HOUSE
    );
    return {
      itemCode: skuId,
      status: skuHasStock
        ? PRODUCT.STOCK_STATUS.AVAILABLE
        : PRODUCT.STOCK_STATUS.NOT_AVAILABLE,
      availableQty: Number(skuHasStock?.available_quantity) || 0
    };
  }, []);
};

const mergeOfflineAndOnlineStock = (
  offlineStocks: StockAvailability[] = [],
  onlineStocks: StockAvailability[] = []
): StockAvailability[] => {
  return offlineStocks.map(offlineStock => {
    const onlineStock = onlineStocks.find(
      onlineStock => onlineStock.itemCode === offlineStock.itemCode
    );
    return {
      ...offlineStock,
      hasOfflineStock: offlineStock.availableQty > 0,
      hasOnlineStock: onlineStock?.availableQty > 0,
      availableQty: offlineStock.availableQty || onlineStock?.availableQty || 0
    };
  });
};

const getOnlineStockFromWarehouse = (
  offlineStocks: OfflineStock[] = [],
  skus: string[] = []
): StockAvailability[] => {
  return skus.map(skuId => {
    const matchedSku = offlineStocks
      .find(offlineStock => offlineStock.skuId === skuId)
      ?.data?.find(
        stockAvailability =>
          stockAvailability.store_id === PRODUCT.STORE_ID.WARE_HOUSE
      );
    const availableQty =
      (PRODUCT.STOCK_AVAILABLE_STATUS.includes(matchedSku?.stock_status) &&
        Number(matchedSku?.available_quantity)) ||
      0;
    return {
      itemCode: skuId,
      availableQty,
      status:
        availableQty > 0
          ? PRODUCT.STOCK_STATUS.AVAILABLE
          : PRODUCT.STOCK_STATUS.NOT_AVAILABLE
    };
  });
};

const getOfflineStock = async (groupId): Promise<OfflineStockResponse[]> => {
  if (!groupId) return [];
  try {
    return await fetchOfflineStock(groupId);
  } catch (e) {
    return [];
  }
};

const checkStock = async (
  skus: string[],
  groupId?: string,
  checkStockFromStore = true
): Promise<{ status: boolean; stock: StockAvailability[] }> => {
  try {
    let onlineStocks = [],
      offLineStocks = [];
    const uniqueSkus = Array.from(new Set(skus));
    const shouldCheckOnlineStockFromEShop = isFeatureFlagEnabled(
      ONLINE_STOCK_FROM_ESHOP
    );
    if (shouldCheckOnlineStockFromEShop) {
      offLineStocks = (await getOfflineStock(groupId)) || [];
      onlineStocks = getOnlineStockFromWarehouse(
        offLineStocks[0]?.items,
        uniqueSkus
      );
    } else {
      [onlineStocks = [], offLineStocks = []] = await Promise.all<
        StockAvailability[],
        OfflineStockResponse[]
      >([
        fetchOnlineStock(uniqueSkus),
        checkStockFromStore ? getOfflineStock(groupId) : []
      ]);
    }
    if (!Array.isArray(onlineStocks)) {
      onlineStocks = [];
    }
    const filteredOfflineStocks = filterOfflineStocks(
      offLineStocks[0]?.items,
      skus
    );

    return {
      status: true,
      stock: mergeOfflineAndOnlineStock(filteredOfflineStocks, onlineStocks)
    };
  } catch (e) {
    return { status: false, stock: [] };
  }
};

const getDeliveryOptions = (
  preOrderLinerForProductDetails: string,
  backOrderLinerForProductDetails: string,
  tags: string[],
  fulfilment: FulfilmentMode[] = [],
  extraLiner = ''
): DeliveryOptions => {
  const fulfilmentList = fulfilment.map<IFulfilment>(fulfilment => {
    const disabled =
      fulfilment.fulfilmentId === PRODUCT.FULFILMENT_TYPES.POP_STATION &&
      fulfilment.tags?.length &&
      !tags.includes(fulfilment.tags[0]);
    return {
      type: fulfilment.fulfilmentId,
      cost: fulfilment.mobileFulfilment.price,
      title: fulfilment.mobileFulfilment.title,
      liner: fulfilment.mobileFulfilment.liner,
      disabled
    };
  });

  return {
    preOrderLiner: preOrderLinerForProductDetails,
    backOrderLiner: backOrderLinerForProductDetails,
    fulfilmentList,
    extraLiner
  };
};

const updateAccessories = (formattedItems, selectedItems) => {
  if (!formattedItems) return;
  const updatedAccessories = formattedItems.map(formattedItem => {
    const preselected = selectedItems.find(
      selectedItem => selectedItem.deviceId === formattedItem.deviceId
    );
    if (preselected) {
      return { ...formattedItem, selectedVariant: preselected };
    }
    return formattedItem;
  });
  return updatedAccessories;
};

const getPromotions = ({
  selectedPlan,
  promotions,
  isCIS
}: {
  selectedPlan: SelectedPlan;
  promotions: KeyValue;
  isCIS: boolean;
}) => {
  const phonePromotion = isCIS ? promotions?.cis : promotions?.res;

  const promoDescription = phonePromotion?.promoDescriptions?.find(desc => {
    return desc.planGroups === selectedPlan?.groupName;
  });

  if (!promoDescription) return null;

  const filteredPromotions = phonePromotion?.promotions?.reduce(
    (result, promotion) => {
      const filteredPromos = promotion.promos?.filter(promo => {
        return (
          promo.planGroups?.length === 0 ||
          includes(promo.planGroups, selectedPlan?.groupName)
        );
      });
      if (filteredPromos.length) {
        result.push({
          ...promotion,
          promos: filteredPromos
        });
      }

      return result;
    },
    []
  );

  return {
    productId: phonePromotion?.productId,
    ...promoDescription,
    promotions: filteredPromotions
  };
};

export const constructUrlParams = (object: KeyValue) => {
  const result = {};
  for (const key in object) {
    if (object[key] !== undefined) {
      result[key] = slugify(object[key], { lower: true });
    }
  }
  return result;
};

export const checkDefaultVariantChanged = (
  defaultVariant: Partial<IVariant & KeyValue>,
  newVariant: Partial<IVariant & KeyValue>
) => {
  return (
    newVariant.colorCode !== defaultVariant?.colorCode ||
    newVariant.memorySize !== defaultVariant?.memorySize ||
    newVariant.planId !== defaultVariant?.planId ||
    newVariant.paymentTerm !== defaultVariant?.paymentTerm ||
    newVariant.monthlyTerm !== defaultVariant?.monthlyTerm
  );
};

export const getDefaultPlan = (
  defaultPlanIds: string[] = [],
  plans: Plan[] = []
) => {
  return (
    plans.find(plan => {
      return defaultPlanIds.includes(plan.planId);
    }) || plans[0]
  );
};

export {
  getDeviceVariants,
  getColorHexCode,
  getImagesByColor,
  getPlanGroups,
  getPlans,
  getProductSpecsFromUrl,
  setUrlAfterChangeVariant,
  checkStock,
  getDeliveryOptions,
  updateAccessories,
  getPromotions
};
