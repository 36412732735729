/* istanbul ignore file */
export default {
  PLANS_GROUP_NAME: {
    SIM_ONLY_PLUS: 'Sim Only Plus'
  },
  INSTALLMENT_MONTHS: ['36', '24', '12'],
  QUERY_PARAMS: [
    'category',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'utm_id',
    'category',
    'brand',
    'sessionToken',
    'roiToken',
    'utm_plan',
    'utm_skipdevice'
  ],
  QUERY_PLANS: {
    SIM_ONLY_PLUS: 'sim-only-plus',
    IPP_TEXT: 'sim-only-plan-with-singtel-paylater',
    XO_PLUS: 'xo-plus'
  },
  QUERYSTRING_TYPE: {
    PLAN: 'plan',
    BRAND: 'brand',
    CATEGORY: 'category',
    IPP: 'ipp',
    SESSIONTOKEN: 'sessionToken',
    GROUP: 'group',
    UTM_SOURCE: 'utm_source',
    UTM_MEDIUM: 'utm_medium',
    UTM_CAMPAIGN: 'utm_campaign',
    UTM_TERM: 'utm_term',
    UTM_CONTENT: 'utm_content',
    UTM_ID: 'utm_id',
    UTM_PLAN: 'utm_plan',
    UTM_SKIPDEVICE: 'utm_skipdevice'
  },
  SPRING_CIS_URL: `${process.env.GATSBY_AEM_URL}/personal/products-services/mobile/cis-plans`,
  FILTER_TYPES: {
    PLAN: 'ipp',
    CATEGORY: 'category',
    BRAND: 'brand',
    PRICE: 'price',
    PRICE_RANGE: 'price_range',
    DEAL: 'deal',
    PAYMENT_OPTION: 'payment_option'
  },
  PAYMENT_TYPES: {
    IPP: 'ipp',
    FULL_PRICE: 'fullprice'
  }
};
