import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import {
  Text,
  Notification,
  TextLink,
  Grid,
  Column,
  Row,
  Spacing
} from '@dls/web';
import SEO from '../SEO';
import LoadingOverlay from '../LoadingOverlay';
import ErrorModal from '../ErrorModal';
import { trans as t } from '../../helpers/localisation';
import notifications from '../../config/notifications.js';
import CONSTANTS from '../../constants/common';
import {
  api,
  CIS_GET_MYINFO_MISMATCH_ERROR,
  orderActions,
  planActions,
  productActions,
  userActions
} from '@detox/actions';
import { useAddonsHelper } from '../../hooks/useAddonsHelper';
import {
  flattenNodes,
  dataLayerPush,
  getAemConfigs,
  getSkipAddonsFromConfig,
  getHasedUserServiceSubID
} from '../../helpers/common';
import persistState from '../../helpers/persist-state';

import { lsKeyName } from '../../config';
import { signUpUrl, mobileShareFaqUrl } from '../../config/links';
import { AUTH } from '../../constants';
import { Button, withTheme, useDevice } from '@dls/web';
import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import {
  getPendingOrderParams,
  shouldShowMSConversionPopup
} from '../../helpers/chooseYourNumberHelper';
import SupplementaryLinesConversionConfirmationModal from '../SupplementaryLinesConversionConfirmationModal';
import { navigateToProductDetailsPage } from '../../helpers/navigation';
import { ACTION_TYPES } from '../../constants/actions';
import { navigation } from '../../middlewares/navigation-constants';
import CCEMisMatchError from '../CCEPrompt/CCEMisMatchErrorModal';
import ExistingServices from '../ExistingServices';
import getIsRoiMismatch from '../../helpers/is-roi-mismatch';
import ChooseSIMType from '../ChooseSIMType';

import { getChooseAddonsDataLayer } from '../../helpers/dataLayerHelpers';
import theme from '../../theme';
import { SkeletonGrid } from '../SkeletonLoader';
import { AEM_CONFIG_KEYS } from '../../config/common';

const { isMobileService } = api.mcss.helpers;

const StyledRowNoMargin = withTheme(styled(props => <Row {...props} />)`
  ${({ coreTheme }) => css`
    @media (min-width: ${coreTheme.brk_sm}) {
      margin: 0 ${theme.spacing(-2)};
    }
  `}
`);

const StyledColumnNoPadding = withTheme(styled(props => <Column {...props} />)`
  margin-bottom: 0;
  ${({ coreTheme }) => css`
    @media (min-width: ${coreTheme.brk_md}) {
      padding: 0 ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(7)};
    }
  `}
`);

function isMobileShareReconFlow(plan = {}) {
  return plan.mobileShareOrderType === 'MS_RECONTRACT';
}

function isMobileShareNewFlow(plan = {}) {
  return plan.mobileShareOrderType === 'MS_NEW';
}

function isMobileShareService(service) {
  return (
    service &&
    service.offeringName
      .toUpperCase()
      .replace(/ /g, '')
      .includes('MOBILESHARE')
  );
}

function assignBlockingError(service, error) {
  if (error && error.serviceId === service.id) {
    return { ...service, errorMsg: error.errorMsg };
  }
  return service;
}

function assignMaxChildError(service, mainLines) {
  const mainLine = mainLines[service.serviceId];
  const hasChildren = mainLine && mainLine.implGroupMember;

  if (hasChildren && mainLine.implGroupMember.length >= 3) {
    return {
      ...service,
      disabled: true,
      errorMsg: 'ELIGIBILITY_MOBILE_SHARE_MAX_3'
    };
  }
  return service;
}

//Allow mobile share if mainlineGroup is found
const hasMainlineGroup = plan => {
  return plan?.mainlineGroup?.toUpperCase() === CONSTANTS.AEM_MS_MAINLINE_GROUP;
};

export const ChooseYourNumber = props => {
  const {
    data,
    selectedPlan,
    selectedProduct,
    userProducts,
    userInformation,
    getUserInfoAndProducts,
    setUserState,
    setSelectedPlan,
    setSelectedProduct,
    setSelectedService,
    setProductOrderError,
    createRecontractOrder,
    sendServiceLineIDtoGTM,
    isCisMyInfo,
    order,
    user,
    auth,
    resetProcessingOrder,
    setSimType,
    resetSimType,
    setAppFlag,
    roiValidatedData,
    addonsLoading
  } = props;

  const { isMobile } = useDevice();

  let {
    allPlan,
    allAddonPreTick,
    allChooseSimOptions,
    allVariableConfig,
    allAddon,
    allPhone: allApplePhone
  } = data;
  const { addonSimOnlyPlan } = data;
  allAddon = useMemo(() => flattenNodes(allAddon), [allAddon]);
  allApplePhone = useMemo(() => flattenNodes(allApplePhone), [allApplePhone]);
  const appleCareAddons = useMemo(
    () =>
      allAddon.filter(({ productTitle }) => productTitle.match(/AppleCare/i)),
    [allAddon]
  );
  const variableConfigs = flattenNodes(allVariableConfig) || [];
  const chooseSIMOptions = useMemo(() => flattenNodes(allChooseSimOptions), [
    allChooseSimOptions
  ]);
  const plans = useMemo(() => flattenNodes(allPlan), [allPlan]);
  allAddonPreTick = useMemo(() => flattenNodes(allAddonPreTick), [
    allAddonPreTick
  ]);
  const [skipAddonsConfig = ''] = getAemConfigs(variableConfigs, [
    AEM_CONFIG_KEYS.DETOX_SKIP_ADDONS_FOR_PLANS
  ]);

  const isMobileShareRecon = isMobileShareReconFlow(selectedPlan);
  const isMobileShareNew = isMobileShareNewFlow(selectedPlan);

  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showMSConversionModal, setShowMSConversionModal] = useState(false);
  const [selectedServiceState, setSelectedServiceState] = useState(null);
  const [serviceSelection, setServiceSelection] = useState(null);
  const selectedAccessories = useSelector(
    state => state.accessory?.selectedAccessories
  );
  const isLoggedIn = userInformation?.clientContext;
  const loadingProducts = user?.loading;
  const loadingOrder = order?.loading || addonsLoading;
  const hasErrors = user?.error;
  const orderError = order?.error;
  const cisError = isCisMyInfo && user?.cis?.information?.cusMyInfo?.error;
  const hasCisError = Boolean(cisError);
  const skipAddons = getSkipAddonsFromConfig(
    skipAddonsConfig,
    selectedPlan?.planRelId
  );

  // commenting out now to handle recon - skip - addons later
  // useAddonsHelper({
  //   allAddon,
  //   addonSimOnlyPlan,
  //   allApplePhone,
  //   appleCareAddons,
  //   skipAddonsConfig,
  //   loadOnInitiate: true
  // });

  useEffect(() => {
    resetProcessingOrder();
  }, []);

  // If we do not have a redux store of selected plan, we need to check
  // if we persisted it inside the localstorage because we could've been
  // redirected here from OnePass success redirection.
  //
  // If neither the redux store or the localstorage have the selected
  // plan, then we navigate the user back to the index page.

  useEffect(() => {
    const stateFromStore = selectedPlan;
    const stateFromLocalStorage = persistState.get(lsKeyName);

    // Neither Redux store or localStorage has the selected plan,
    // we navigate back to index page.
    if (!stateFromStore && stateFromLocalStorage === null) {
      navigate('/', { replace: true });
    }

    // Redux store and localstorage is found
    if (stateFromStore && stateFromLocalStorage) {
      persistState.remove(lsKeyName);
    }
  }, [
    selectedPlan,
    selectedProduct,
    setSelectedPlan,
    setSelectedProduct,
    setUserState
  ]);
  // We should not be on this page if we don't have a selected plan
  // so we navigate back to the home page.
  // Otherwise, get user information on load
  useEffect(() => {
    if (isCisMyInfo && hasCisError) {
      return;
    }
    if (selectedPlan) {
      const options = {
        getMainLinesOnly: isMobileShareNew,
        shouldFetchMainLines: isSimOnlyPlan(selectedPlan)
      };
      getUserInfoAndProducts(isMobileService, options);
    }
  }, [
    hasCisError,
    isCisMyInfo,
    getUserInfoAndProducts,
    isMobileShareNew,
    selectedPlan
  ]);

  useEffect(() => {
    if (order?.hasProcessingOrder) {
      navigate(
        `/${navigation.INFO}${getPendingOrderParams(
          !!user.information?.userDetails?.loginId
        )}`
      );
      resetProcessingOrder();
    }
  }, [order?.hasProcessingOrder]);

  useEffect(() => {
    setAppFlag({ skipAddons });
  }, []);

  const onServiceClick = service => {
    if (isMobileShareNew) {
      setSelectedService(service);
      navigate('/number-selection');
    } else if (shouldShowMSConversionPopup(selectedPlan, user, service)) {
      setSelectedServiceState(service);
      setShowMSConversionModal(true);
    } else {
      sendServiceLineIDtoGTM(getHasedUserServiceSubID(user, service));
      setSelectedService(service);
      createRecontractOrder({
        service,
        selectedPlan,
        simpleProductSpecId: selectedProduct?.productId,
        contact: user.information.clientContext.contact,
        cisDetails: user.cis.information,
        preTickAddons: allAddonPreTick,
        monthlyTerm: selectedProduct?.monthlyTerm,
        selectedProduct: selectedProduct?.groupId,
        accessories: selectedAccessories
      });
    }
  };

  let userServices = (userProducts || [])
    .filter(service => service)
    .map(service => assignBlockingError(service, orderError));

  if (isMobileShareNew && user.mainLines) {
    // Filter plans eligible for mobileshare and
    // check for max mobile share line error when choosing main line
    userServices = userServices
      .filter(service => {
        return plans.some(
          plan => plan.planId === service.offeringId && hasMainlineGroup(plan)
        );
      })
      .map(service => assignMaxChildError(service, user.mainLines));
  }

  if (isMobileShareRecon) {
    userServices = userServices.filter(isMobileShareService);
  }

  const userServiceWithError = userServices.find(
    service => service && service.errorMsg === 'RECONTRACT_GENERIC_ERROR'
  );

  const hasProducts = userServices.length > 0;

  const handleErrorModalClose = () => {
    setProductOrderError(false);
  };

  const onMobileShareToSimOnlyConversionConfirmation = () => {
    setShowMSConversionModal(false);
    setSelectedService(selectedServiceState);
    createRecontractOrder({
      service: selectedServiceState,
      selectedPlan,
      simpleProductSpecId: selectedProduct?.productId,
      contact: user.information.clientContext.contact,
      cisDetails: user.cis.information,
      preTickAddons: allAddonPreTick,
      monthlyTerm: selectedProduct?.monthlyTerm,
      selectedProduct: selectedProduct?.groupId,
      accessories: selectedAccessories
    });
  };

  const onChooseAnotherPlan = () => {
    setShowMSConversionModal(false);
    navigateToProductDetailsPage(selectedProduct, selectedPlan);
  };

  const handleFaqClick = () => {
    window.open(mobileShareFaqUrl, '_blank', 'noopener,noreferrer');
  };

  const handleChangeToMSNew = () => {
    setSelectedPlan({ ...selectedPlan, mobileShareOrderType: 'MS_NEW' });
    getUserInfoAndProducts(isMobileService, { getMainLinesOnly: true });
  };

  const handleNavigateToPlansPage = () => {
    if (isMobileShareRecon) {
      return handleChangeToMSNew();
    }
    setSelectedPlan(plans[0]);
    navigate(`/${navigation.MAIN_PLANS_PAGE}`, { replace: true });
  };

  let renderErrorNote;
  let renderErrorMessage;
  let renderErrorSubMessage;
  let pageTitle;
  let pageSubtitle;
  let serviceCtaText;
  if (isMobileShareNew) {
    renderErrorNote = t('MOBILE_SHARE_NEW_PAGE_ERROR_LINER', {
      tnc: (
        <TextLink type="smallBody" onClick={handleFaqClick}>
          {t('MOBILE_SHARE_ERROR_LINER_FAQ')}
        </TextLink>
      )
    });
    renderErrorMessage = t('MOBILE_SHARE_NO_SERVICES_FOUND');
    renderErrorSubMessage = null;
    pageTitle = t('MOBILE_SHARE_NEW_PAGE_TITLE');
    pageSubtitle = null;
    serviceCtaText = t('GET_A_MAIN_LINE');
  } else if (isMobileShareRecon) {
    renderErrorNote = null;
    renderErrorMessage = notifications.MS_RECONTRACT_NO_SERVICES_FOUND.text;
    pageTitle = t('MOBILE_SHARE_RECON_PAGE_TITLE');
    pageSubtitle = null;
    serviceCtaText = t('GET_A_NEW_LINE');
  } else if (cisError === CIS_GET_MYINFO_MISMATCH_ERROR) {
    renderErrorNote = (
      <Fragment>
        <span>{notifications.CIS_MYINFO_MISMATCHED_ERROR.text}</span>
        <Spacing top={3}>
          {/*TODO: Move this to env variable */}
          <Button
            onClick={() =>
              (window.location.href = `${process.env.GATSBY_AEM_URL}${navigation.SPRINGD_MYINFO_PAGE_PATH}`)
            }
          >
            Try again
          </Button>
        </Spacing>
      </Fragment>
    );
    renderErrorMessage = null;
    pageTitle = t('CIS_MYINFO_MISMATCH_ERROR_PAGE_TITLE');
    serviceCtaText = 'Recontract';
  } else if (cisError === AUTH.MYINFO_FAILED) {
    renderErrorNote = (
      <Fragment>
        <TextLink inline href={signUpUrl}>
          Sign up
        </TextLink>{' '}
        for a new line?
      </Fragment>
    );

    renderErrorMessage = notifications.CIS_MYINFO_FAILED_ERROR.text;
    pageTitle = t('CIS_MYINFO_FAILED_ERROR_PAGE_TITLE');
    pageSubtitle = t('CIS_MYINFO_FAILED_ERROR_PAGE_SUBTITLE');
    serviceCtaText = 'Recontract';
  } else {
    renderErrorNote = null;
    renderErrorMessage = notifications.RECONTRACT_NO_SERVICES_FOUND.text;
    pageTitle = t('RECONTRACT_NO_SERVICES_FOUND_PAGE_TITLE');
    pageSubtitle = null;
    serviceCtaText = t('RECONTRACT_NO_SERVICES_FOUND_GET_A_NEW_LINE');
  }

  const proceedClicked = () => {
    navigate('/', { replace: true });
  };

  const hasErrorNote =
    (!hasProducts && !loadingProducts) || (isCisMyInfo && Boolean(cisError));
  const showServicesList = !Boolean(cisError) && hasProducts;
  const hasRoiDataMismatch = getIsRoiMismatch(
    userInformation?.userDetails?.custId,
    roiValidatedData
  );

  const handleServiceSelection = service => {
    if (order?.productOrder && !isMobileShareNew) {
      setShowPromptModal(true);
      setSelectedServiceState(service);
    } else {
      onServiceClick(service);
    }
  };

  const pushDataLayer = selectedSimType => {
    dataLayerPush(
      getChooseAddonsDataLayer({
        selectedPlan,
        selectedVariant: selectedProduct,
        accessories: selectedAccessories,
        location: window.location,
        order: { sim: { selectedSimType } }
      }),
      true
    );
  };

  const servicesProps = {
    selectedPlan: selectedPlan,
    plans: plans,
    selectedProduct: selectedProduct,
    services: userServices,
    ctaText: serviceCtaText
  };

  return (
    <>
      <SEO title="Choose Your Number" />
      <Grid>
        {loadingOrder && <LoadingOverlay />}
        {isLoggedIn && (
          <Row>
            <Column>
              <Spacing bottom={3}>
                <Text type="pageTitle">{pageTitle}</Text>
              </Spacing>
              {hasErrors && (
                <Notification
                  type="alert"
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: notifications.RECONTRACT_GENERIC_ERROR.text
                      }}
                    />
                  }
                />
              )}
              {userServiceWithError && (
                <ErrorModal
                  open={true}
                  errorMessage={{
                    title:
                      notifications[
                        userServiceWithError.errorMsg ||
                          'RECONTRACT_GENERIC_ERROR'
                      ].title,
                    text:
                      notifications[
                        userServiceWithError.errorMsg ||
                          'RECONTRACT_GENERIC_ERROR'
                      ].text
                  }}
                  onClose={handleErrorModalClose}
                  onCtaClick={handleErrorModalClose}
                />
              )}

              {hasRoiDataMismatch && (
                <CCEMisMatchError
                  proceedClicked={proceedClicked}
                  isLoggedInUser={true}
                />
              )}

              {!hasErrors && !hasRoiDataMismatch && (
                <Fragment>
                  {pageSubtitle && (
                    <Spacing bottom={isMobile ? 2 : 1}>
                      <Text type="header">{pageSubtitle}</Text>
                    </Spacing>
                  )}

                  {!loadingProducts && showServicesList && (
                    <ExistingServices
                      {...servicesProps}
                      onServiceSelection={selectedService => {
                        if (isMobileShareNew && selectedService) {
                          handleServiceSelection(selectedService);
                        } else {
                          setServiceSelection(selectedService);
                          if (!selectedService) {
                            resetSimType();
                          }
                        }
                      }}
                    />
                  )}

                  {serviceSelection && !isMobileShareNew && (
                    <Spacing bottom={5}>
                      <ChooseSIMType
                        data={chooseSIMOptions}
                        selectedProduct={selectedProduct}
                        showSubtitle
                        scrollToSimType
                        transactionTypeValue="recontract"
                        columnProps={{ xs: 12, sm: 12, md: 8, lg: 8 }}
                        onSimTypeOnSelection={simTypeValue => {
                          pushDataLayer(simTypeValue);
                          setSimType(simTypeValue);
                          handleServiceSelection(serviceSelection);
                        }}
                        enablePDForm
                        aemVariableConfigs={variableConfigs}
                        btnText={
                          skipAddons ? t('PROCEED') : t('CHOOSE_ADD_ONS')
                        }
                      />
                    </Spacing>
                  )}

                  {loadingProducts && (
                    <StyledRowNoMargin>
                      <StyledColumnNoPadding xs={12} sm={8}>
                        <SkeletonGrid
                          numberOfColumn={isMobile ? 1 : 2}
                          numberOfRow={2}
                          isLoading={true}
                        />
                      </StyledColumnNoPadding>
                    </StyledRowNoMargin>
                  )}

                  <Row>
                    <Column sm={12} md={8} noGutter>
                      {hasErrorNote && (
                        <Spacing bottom={5} bottomMd={9}>
                          {renderErrorMessage && (
                            <Spacing bottom={3} bottomMd={5}>
                              <Notification
                                type="alert"
                                title={renderErrorMessage}
                                content={renderErrorSubMessage}
                                action={{
                                  type: 'button',
                                  text: serviceCtaText,
                                  onClick: handleNavigateToPlansPage
                                }}
                              />
                            </Spacing>
                          )}
                          <Text>{renderErrorNote}</Text>
                        </Spacing>
                      )}
                    </Column>
                  </Row>
                </Fragment>
              )}
            </Column>
          </Row>
        )}
      </Grid>

      {showMSConversionModal && (
        <SupplementaryLinesConversionConfirmationModal
          visible={showMSConversionModal}
          selectedPlanName={selectedPlan?.planName}
          user={user}
          selectedServiceState={selectedServiceState}
          onClose={() => {
            setShowMSConversionModal(false);
          }}
          onMobileShareToSimOnlyConversionConfirmation={
            onMobileShareToSimOnlyConversionConfirmation
          }
          onChooseAnotherPlan={onChooseAnotherPlan}
        />
      )}

      <ErrorModal
        open={showPromptModal}
        errorMessage={{
          title: notifications['ORDER_WIPE_PROMPT'].title,
          serviceNumber: selectedServiceState?.serviceId,
          text: notifications['ORDER_WIPE_PROMPT'].text,
          ctaText: notifications['ORDER_WIPE_PROMPT'].ctaText,
          secondaryCtaText: notifications['ORDER_WIPE_PROMPT'].secondaryCtaText
        }}
        onClose={() => {
          setShowPromptModal(false);
        }}
        onCtaClick={() => {
          onServiceClick(selectedServiceState);
          setShowPromptModal(false);
        }}
        onSecondaryCtaClick={() => {
          setShowPromptModal(false);
        }}
      />
    </>
  );
};

ChooseYourNumber.defaultProps = {
  getUserInfoAndProducts: () => {},
  setSelectedService: () => {}
};

ChooseYourNumber.propTypes = {
  data: PropTypes.object,
  createRecontractOrder: PropTypes.func,
  getUserInfoAndProducts: PropTypes.func,
  selectedPlan: PropTypes.object,
  selectedProduct: PropTypes.object,
  userInformation: PropTypes.object,
  userProducts: PropTypes.array,
  setSelectedPlan: PropTypes.func,
  setSelectedProduct: PropTypes.func,
  setSelectedService: PropTypes.func,
  setUserState: PropTypes.func,
  auth: PropTypes.object,
  order: PropTypes.object,
  user: PropTypes.object,
  roiValidatedData: PropTypes.object
};

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    selectedProduct: state.product.selectedProduct,
    selectedPlan: state.plan.selectedPlan,
    userInformation: state.user.information,
    userProducts: state.user.products,
    order: state.order,
    user: state.user,
    auth: state.auth,
    isCisMyInfo:
      Boolean(state.user?.cis?.information) &&
      Boolean(state.user?.cis?.information?.$myInfo),
    roiValidatedData: state.iphone.validateSkuData,
    addonsLoading: state.addons.isSkeletonLoading
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const { createRecontractOrder, setProductOrderError } = orderActions;
  const {
    setUserState,
    getUserInfoAndProducts,
    setSelectedService
  } = userActions;
  const { setSelectedProduct } = productActions;
  const { setSelectedPlan } = planActions;

  return {
    createRecontractOrder: options => dispatch(createRecontractOrder(options)),
    getUserInfoAndProducts: (filter, options) =>
      dispatch(getUserInfoAndProducts(filter, options)),
    setUserState: user => dispatch(setUserState(user)),
    setSelectedProduct: product => dispatch(setSelectedProduct(product)),
    setSelectedPlan: plan => dispatch(setSelectedPlan(plan)),
    setSelectedService: service => dispatch(setSelectedService(service)),
    setProductOrderError: error => dispatch(setProductOrderError(error)),
    resetProcessingOrder: () =>
      dispatch({ type: ACTION_TYPES.ORDER.RESET_PROCESSING_ORDER }),
    setSimType: type =>
      dispatch({ type: ACTION_TYPES.SIMTYPE.SET_SIMTYPE, payload: type }),
    resetSimType: () => dispatch({ type: ACTION_TYPES.SIMTYPE.RESET_SIMTYPE }),
    setAppFlag: value => {
      dispatch({
        type: ACTION_TYPES.SETTINGS.SET_APP_CONFIG,
        payload: value
      });
    },
    sendServiceLineIDtoGTM: hasedUserSubServiceID => {
      dispatch({
        type: ACTION_TYPES.GTM_SEND_EVENTS.SEND_SERVICE_LINE,
        payload: {
          platform_user_id: hasedUserSubServiceID,
          platform_user_id_type: 'mob_recon_postpaid',
          said: ''
        }
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseYourNumber);
