import { Grid, Button, Row, Column } from '@dls/web';
import React, { useState, useMemo, ReactNode, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { rrpCheckoutActions, OTP_ERROR_CODES } from '@detox/actions';
import { Verify } from '@common-modules/registration-checkout';

import { trans as t } from '../../helpers/localisation';
import { formatContactNumber } from '../../helpers/common';
import { navigation } from '../../middlewares/navigation-constants';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';

import CONSTANTS from '../../constants/checkout';
import { rrpCheckoutState } from '../../reducers/rrpCheckout';
import {
  RRPRegistrationFormConfig,
  rrpRegistrationValidationSchema,
  PAGE
} from './config';
import { Result, ResultStatusType } from '../Result/index';
import GenericError from '../ShoppingCart/GenericError';
import { rrpCustomerRegistrationDataLayer } from '../../helpers/dataLayerHelpers';
import { ACTION_TYPES } from '../../constants/actions';
import { BE_ERROR_INFO } from '../../config/common';

export const CustomerRegistration = (): ReactNode => {
  const dispatch = useDispatch();
  const { registerCustomer, sendOtp, resendOtp } = rrpCheckoutActions;
  const [otpErrorMsg, setOtpErrorMsg] = useState({
    error: '',
    isLoading: false
  });

  const { rrpCheckout, customerDetails, rrpCart } = useSelector<
    { rrpCheckout: rrpCheckoutState; rrpCart: APP_TYPE_ANY },
    KeyValue
  >(state => ({
    rrpCheckout: state?.rrpCheckout,
    customerDetails: state?.rrpCart?.rrpOrder?.customerDetails,
    rrpCart: state.rrpCart
  }));

  useEffect(() => {
    if (
      rrpCheckout.errorInfo?.errorInfo?.backendErrorInfo?.includes(
        BE_ERROR_INFO.ORDER_SUBMITTED
      )
    ) {
      dispatch({
        type: ACTION_TYPES.RRP.RRP_CLEAR_ORDER_DATA,
        value: { showEmptyCart: true }
      });
    }
  }, [rrpCheckout.isError]);

  const [screen, setScreen] = useState(PAGE.CUSTOMER_REGISTRATION);
  const [authStatus, setAuthStatus] = useState(
    CONSTANTS.SHOW_CUSTOM_VERIFICATION_FORM
  );
  const [userInfo, setUserInfo] = useState<APP_TYPE_ANY>();
  const [formValues, setFormValues] = useState({
    fullName: customerDetails?.name,
    email: customerDetails?.email,
    mobNumber: customerDetails?.phone
      ? formatContactNumber(customerDetails.phone)
      : '',
    termsAccepted: false
  });

  const onFormValueChange = (values: APP_TYPE_ANY) => {
    setFormValues(values);
  };

  const errorMsgMapping = {
    [OTP_ERROR_CODES.INVALID_OTP]: t('VERIFICATION_OTP_INVALID_OTP_ERROR_MSG'),
    [OTP_ERROR_CODES.EXPIRED_OTP]: t('VERIFICATION_OTP_EXPIRED_OTP_ERROR_MSG')
  };

  const getPageBasedOnErrorCode = (errorCode: string) => {
    switch (errorCode) {
      case OTP_ERROR_CODES.OTP_LIMIT_REACHED:
        return PAGE.OTP_LIMIT_EXCEEDED;
      case OTP_ERROR_CODES.OTP_API_DOWN:
      case OTP_ERROR_CODES.OTP_VERIFICATION_BLOCKED:
        return PAGE.PAGE_ERROR;
      case OTP_ERROR_CODES.INVALID_OTP:
      case OTP_ERROR_CODES.EXPIRED_OTP:
      default:
        return screen;
    }
  };

  const handleError = (errorCode: string) => {
    const page = getPageBasedOnErrorCode(errorCode);
    setOtpErrorMsg({
      error: errorMsgMapping[errorCode] as string,
      isLoading: false
    });
    setScreen(page);
  };

  const onFormSubmit = async (values: KeyValue) => {
    const userInfo = {
      email: values.email,
      name: values.fullName,
      phone: values.mobNumber.split('+65 ')[1].replace(' ', '')
    };
    const res = (await dispatch(
      sendOtp({
        rrpMasterOrderId: rrpCheckout.rrpMasterOrderId,
        userInfo
      })
    )) as APP_TYPE_ANY;
    setUserInfo(userInfo);

    if (res?.isCustomerRegistered) {
      navigate(`/${navigation.RRP_FULFILMENT}`);
    } else if (res?.isOtpSend) {
      setScreen(PAGE.OTP);
      setAuthStatus(CONSTANTS.CHECKOUT_VERIFY_OTP);
    } else {
      res?.errorCode && handleError(res.errorCode);
    }
  };

  const handleRegisterCustomer = async (otp: string) => {
    const res = (await dispatch(
      registerCustomer({
        userInfo: { ...userInfo, otp },
        rrpMasterOrderId: rrpCheckout.rrpMasterOrderId
      })
    )) as APP_TYPE_ANY;

    res && !res?.errorCode
      ? setScreen(PAGE.OTP_SUCCESS)
      : handleError(res?.errorCode);
  };

  const handleResendOtp = async () => {
    const res = (await dispatch(
      resendOtp({
        rrpMasterOrderId: rrpCheckout.rrpMasterOrderId,
        userInfo
      })
    )) as APP_TYPE_ANY;

    res?.errorCode && handleError(res.errorCode);
  };

  const RRPCustomerRegistrationData = {
    title: t('ENTER_YOUR_DETAILS'),
    form: {
      initialValues: formValues,
      data: {},
      onSubmit: onFormSubmit,
      formFieldsConfig: RRPRegistrationFormConfig(t),
      onValueChange: onFormValueChange,
      validationSchema: rrpRegistrationValidationSchema(t)
    }
  };

  const screenData = useMemo(() => {
    switch (screen) {
      case PAGE.CUSTOMER_REGISTRATION:
        return RRPCustomerRegistrationData;
      case PAGE.OTP:
        return {
          data: {
            mobileNumber: formValues.mobNumber,
            resendRequired: true
          }
        };
      default:
        return null;
    }
  }, [screen]);

  const renderCustomerRegistration = () => {
    return (
      <>
        <Verify
          authStatus={authStatus}
          data={screenData}
          isLoading={otpErrorMsg.isLoading}
          configs={{
            onePassLoginRequired: false,
            otpTimeInterval: 30,
            fullWidth: true
          }}
          errorMessage={otpErrorMsg.error}
          callbacks={{
            onOtpVerified: (verifiedData: KeyValue = {}) => {
              const { otp } = verifiedData;
              setOtpErrorMsg(prevState => ({
                ...prevState,
                isLoading: true
              }));
              handleRegisterCustomer(otp);
            },
            onNricVerified: () => {
              handleResendOtp();
            }
          }}
        />
      </>
    );
  };

  const renderScreenBasedOnStatus = () => {
    switch (screen) {
      case PAGE.OTP_SUCCESS: {
        const navigateToFulfilment = () => {
          rrpCustomerRegistrationDataLayer({
            baseParams: {
              cartItems: rrpCart?.addedItems,
              location: window.location
            }
          });
          navigate(`/${navigation.RRP_FULFILMENT}`);
        };
        return navigateToFulfilment();
      }
      case PAGE.OTP_LIMIT_EXCEEDED: {
        const errorDataModel = {
          status: 'error' as ResultStatusType,
          statusText: t('OTP_REQUESTS_REACHED'),
          subTitle: t('OTP_TRY_AGAIN_AFTER_30_MINUTES'),
          action: (
            <Button
              secondary
              text={t('BACK_TO_LOGIN')}
              data-testid="otp-limit-exceeded"
              onClick={() => {
                setAuthStatus(CONSTANTS.SHOW_CUSTOM_VERIFICATION_FORM);
                setScreen(PAGE.CUSTOMER_REGISTRATION);
              }}
            />
          )
        };
        return <Result {...errorDataModel} />;
      }
      case PAGE.PAGE_ERROR:
        return (
          <GenericError
            title={t('GENERIC_ERROR_TITLE')}
            errorText={t('GENERIC_ERROR_MESSAGE')}
            secondaryButtonText={t('TRY_AGAIN')}
            onSecondaryClick={() => {
              setAuthStatus(CONSTANTS.SHOW_CUSTOM_VERIFICATION_FORM);
              setScreen(PAGE.CUSTOMER_REGISTRATION);
            }}
          />
        );
      default:
        return renderCustomerRegistration();
    }
  };

  return (
    <>
      <Grid>
        <Row noGutter>
          <Column xs={12} sm={12}>
            {renderScreenBasedOnStatus()}
          </Column>
        </Row>
      </Grid>
    </>
  );
};

export default CustomerRegistration;
